import { v4 as uuidv4 } from "uuid";

export const fieldsPersonalDetails = () => {
  return [
    {
      uuid: uuidv4(),
      id: "customerEmail",
      name: "Email",
      type: "field",
      settings: {
        detail: "customerEmail",
        placeholder: "Insira seu Email",
        autofillParam: "mail",
      },
      fieldType: "personal_details",
    },
    {
      uuid: uuidv4(),
      id: "customerName",
      name: "Nome do cliente",
      type: "field",
      settings: {
        detail: "customerName",
        placeholder: "Insira o Nome do cliente",
      },
      fieldType: "personal_details",
    },
    {
      uuid: uuidv4(),
      id: "customerSocialNumber",
      name: "CPF do cliente",
      type: "field",
      settings: {
        detail: "customerSocialNumber",
        placeholder: "Insira o CPF do cliente",
      },
      fieldType: "personal_details",
    },
    {
      uuid: uuidv4(),
      id: "customerPhoneNumber",
      name: "Telefone do cliente",
      type: "field",
      settings: {
        detail: "customerPhoneNumber",
        placeholder: "Insira o telefone do cliente.",
      },
      fieldType: "personal_details",
    },
  ];
};
