import { FieldProps } from "./functionsInterfaces";

export const generateJSON = (
  structureForm: any,
  isCreated: boolean,
  pageId: string,
  formId: string,
  nameForm: string,
  skillIdForm: string,
  tabIdForm: string,
  aliasFormResult: string,
  form: any
) => {
  let json: any;

  if (isCreated) {
    json = {
      name: nameForm,
      skillId: skillIdForm,
      tabId: tabIdForm,
      aliases: [aliasFormResult],
      template: {
        children: [
          {
            id: structureForm.id,
            name: structureForm.name,
            type: "page",
            children: structureForm.children.map((item: any) => {
              if (item.type === "group") {
                return {
                  id: item.id,
                  name: item.name,
                  hideName: item.hideName,
                  type: "group",
                  children: item.children.map((field: any) => {
                    if (field.type === "group") {
                      return {
                        id: field.id,
                        name: field.name,
                        hideName: field.hideName,
                        type: "group",
                        children: field.children.map((field: FieldProps) => {
                          if (field.fieldType === "personal_details") {
                            return {
                              id: field.id,
                              name: field.name,
                              isRequired: field.isRequired,
                              show: field.show,
                              type: "field",
                              fieldType: field.fieldType,
                              settings: field.settings,
                            };
                          } else {
                            return {
                              id: field.id,
                              name: field.name,
                              isKey: field.isKey,
                              isRequired: field.isRequired,
                              show: field.show,
                              type: "field",
                              fieldType: field.fieldType,
                              settings: field.settings,
                            };
                          }
                        }),
                      };
                    } else if (field.fieldType === "personal_details") {
                      return {
                        id: field.id,
                        name: field.name,
                        isRequired: field.isRequired,
                        show: field.show,
                        type: "field",
                        fieldType: field.fieldType,
                        settings: field.settings,
                      };
                    } else {
                      return {
                        id: field.id,
                        name: field.name,
                        isKey: field.isKey,
                        isRequired: field.isRequired,
                        show: field.show,
                        type: "field",
                        fieldType: field.fieldType,
                        settings: field.settings,
                      };
                    }
                  }),
                };
              } else if (item.type === "field") {
                if (item.fieldType === "personal_details") {
                  return {
                    id: item.id,
                    name: item.name,
                    isRequired: item.isRequired,
                    show: item.show,
                    type: "field",
                    fieldType: item.fieldType,
                    settings: item.settings,
                  };
                } else {
                  return {
                    id: item.id,
                    name: item.name,
                    isKey: item.isKey,
                    isRequired: item.isRequired,
                    show: item.show,
                    type: "field",
                    fieldType: item.fieldType,
                    settings: item.settings,
                  };
                }
              }
            }),
          },
        ],
        attachments: [] as string[],
        personalDetails: {} as Record<string, string>,
      },
    };

    if (formId) {
      json.id = formId;
    } else {
      json;
    }

    form.forEach((item: any) => {
      if (item.type === "group") {
        item.children.forEach((field: FieldProps) => {
          if (field.fieldType === "multiple_files") {
            const path: string = `data.${item.id}.${field.id}`;
            json.template.attachments.push(path);
          }
          if (field.fieldType === "personal_details") {
            const personalDetails: any = json.template.personalDetails;
            personalDetails[field.id] = `data.${item.id}.${field.id}`;
          }
        });
      } else if (item.type === "field") {
        if (item.fieldType === "multiple_files") {
          const path: string = `data.${item.id}`;
          json.template.attachments.push(path);
        }
        if (item.fieldType === "personal_details") {
          const personalDetails: any = json.template.personalDetails;
          personalDetails[item.id] = `data.${item.id}`;
        }
      }
    });
  } else {
    json = {
      name: nameForm,
      skillId: skillIdForm,
      tabId: tabIdForm,
      aliases: [aliasFormResult],
      template: {
        children: [
          {
            id: pageId,
            name: nameForm,
            type: "page",
            children: form.map((item: any) => {
              if (item.type === "group") {
                return {
                  id: item.id,
                  name: item.name,
                  hideName: item.hideName,
                  type: "group",
                  children: item.children.map((field: any) => {
                    if (field.type === "group") {
                      return {
                        id: field.id,
                        name: field.name,
                        hideName: field.hideName,
                        type: "group",
                        children: field.children.map((field: FieldProps) => {
                          if (field.fieldType === "personal_details") {
                            return {
                              id: field.id,
                              name: field.name,
                              isRequired: field.isRequired,
                              show: field.show,
                              type: "field",
                              fieldType: field.fieldType,
                              settings: field.settings,
                            };
                          } else {
                            return {
                              id: field.id,
                              name: field.name,
                              isKey: field.isKey,
                              isRequired: field.isRequired,
                              show: field.show,
                              type: "field",
                              fieldType: field.fieldType,
                              settings: field.settings,
                            };
                          }
                        }),
                      };
                    } else if (field.fieldType === "personal_details") {
                      return {
                        id: field.id,
                        name: field.name,
                        isRequired: field.isRequired,
                        show: field.show,
                        type: "field",
                        fieldType: field.fieldType,
                        settings: field.settings,
                      };
                    } else {
                      return {
                        id: field.id,
                        name: field.name,
                        isKey: field.isKey,
                        isRequired: field.isRequired,
                        show: field.show,
                        type: "field",
                        fieldType: field.fieldType,
                        settings: field.settings,
                      };
                    }
                  }),
                };
              } else if (item.type === "field") {
                if (item.fieldType === "personal_details") {
                  return {
                    id: item.id,
                    name: item.name,
                    isRequired: item.isRequired,
                    show: item.show,
                    type: "field",
                    fieldType: item.fieldType,
                    settings: item.settings,
                  };
                } else {
                  return {
                    id: item.id,
                    name: item.name,
                    isKey: item.isKey,
                    isRequired: item.isRequired,
                    show: item.show,
                    type: "field",
                    fieldType: item.fieldType,
                    settings: item.settings,
                  };
                }
              }
            }),
          },
        ],
        attachments: [] as string[],
        personalDetails: {} as Record<string, string>,
      },
    };

    if (formId) {
      json.id = formId;
    } else {
      json;
    }

    form.forEach((item: any) => {
      if (item.type === "group") {
        item.children.forEach((field: FieldProps) => {
          if (field.fieldType === "multiple_files") {
            const path: string = `data.${item.id}.${field.id}`;
            json.template.attachments.push(path);
          }
          if (field.fieldType === "personal_details") {
            const personalDetails: any = json.template.personalDetails;
            personalDetails[field.id] = `data.${item.id}.${field.id}`;
          }
        });
      } else if (item.type === "field") {
        if (item.fieldType === "multiple_files") {
          const path: string = `data.${item.id}`;
          json.template.attachments.push(path);
        }
        if (item.fieldType === "personal_details") {
          const personalDetails: any = json.template.personalDetails;
          personalDetails[item.id] = `data.${item.id}`;
        }
      }
    });
  }
  return json;
};
