import { InputHTMLAttributes, TextareaHTMLAttributes } from 'react'
import styles from './styles.module.scss'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  skillName?: string
  tabName?: string
}

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> { }

export function Input({ ...rest }: InputProps) {
  return <input className={styles.input} {...rest} />
}

export function TextArea({ ...rest }: TextAreaProps) {
  return <textarea className={styles.input} {...rest}></textarea>
}

export function InputsInfosPages({ ...rest }: InputProps) {
  return <input type='text' className={styles.inputsInfosPages} {...rest} />
}

export function InputsInfosFields({ ...rest }: InputProps) {
  return <input type='text' className={styles.inputsInfosFields} {...rest} />
}

export function InputsInfosGroups({ ...rest }: InputProps) {
  return <input type='text' className={styles.inputsInfosGroups} {...rest} />
}

export function InputSkill({ skillName, ...rest }: InputProps) {
  return <input type='text' title={skillName} className={styles.inputsInfosPages} {...rest} />
}

export function InputTab({ tabName, ...rest }: InputProps) {
  return <input type='text' title={tabName} className={styles.inputsInfosPages} {...rest} />
}