import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Helmet } from 'react-helmet-async';
import { Header } from '../../components/Header';
import style from './style.module.scss';
import { getAllForms } from '../../utils/functions/functionsLoadDataForm';

export default function ListForms() {
  const navigate = useNavigate()
  const { authorizationRA, user } = useAuthContext();
  const ambient = user?.ambient as string;
  const clientId = user?.clientId as string
  const [allForm, setAllForm] = useState<any[]>([])
  const [loadingPage, setLoadingPage] = useState(false);
  // const [cacheClient, setCacheClient] = useState<string[]>([])

  // const useIsomorphicEffect =useEffect

  // const clearCacheClient = async () => {
  //   if (cacheClient) cacheClient?.forEach((key) => caches?.delete(key))
  //   window.location.replace(window.location.href + "?reloaded=true")
  // }

  // useIsomorphicEffect(() => {
  //   getAllForms(setLoadingPage, authorizationRA, ambient, setAllForm);

  //   const getCachesClient = async () => {
  //     const cachesData = await caches?.keys()
  //     if (cachesData) setCacheClient(cachesData)
  //   }
  //   getCachesClient()
  // }, [authorizationRA, ambient])

  // useIsomorphicEffect(() => {
  //   if (cacheClient?.length > 0) clearCacheClient()
  // }, [cacheClient])

  useEffect(() => {
    getAllForms(setLoadingPage, authorizationRA, ambient, setAllForm);
  }, [authorizationRA, ambient])

  const handleFormClick = (formId: string) => {
    navigate(`/updateForm?clientId=${clientId}&formId=${formId}`, { state: { formId } });
  };

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Listagem dos Formulários</title>
      </Helmet>
      <Header />

      <div className={style.container}>
        {loadingPage ? (
          <div className={style.spinnerContainer}>
            <FontAwesomeIcon icon={faSpinner} size="2x" style={{ color: "#ffffff" }} />
          </div>
        ) : (
          <>
            {
              allForm.map((form: any) => (
                <div key={form.id} onClick={() => handleFormClick(form.id)} className={style.form}>
                  <div className={`${style.items} ${style.item}`}>
                    <span className={style['item-title']}>Id:</span> {form.id}
                  </div>
                  <div className={`${style.items} ${style.item}`}>
                    <span className={style['item-title']}>Nome:</span> {form.name}
                  </div>
                  <div className={`${style.status} ${form.status === 'published' ? style.published : style.unpublished}`}>
                    {form.status}
                  </div>
                  <div className={`${style.items} ${style.item}`}>
                    <span className={style['item-title']}>Criado em: </span> {format(new Date(form.createdAt), 'dd/MM/yyyy HH:mm', { locale: ptBR })}
                  </div>
                  <div className={`${style.items} ${style.item}`}>
                    <span className={style['item-title']}>ItemId: </span> {form.itemId}
                  </div>
                  <div className={`${style.items} ${style.item}`}>
                    <span className={style['item-title']}>Atualizado em: </span> {format(new Date(form.updatedAt), 'dd/MM/yyyy HH:mm', { locale: ptBR })}
                  </div>
                </div>
              ))
            }
          </>
        )}
      </div>
    </div>
  );
}
