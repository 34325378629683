import { useAuthContext } from '../../hooks/auth'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { FiArrowLeft, FiLogOut } from 'react-icons/fi'
import style from './styles.module.scss'
import logoImg from '../../assets/tech4humans.png';
import { useEffect, useState } from 'react';

export function Header({ home = false }) {
  const navigate = useNavigate()
  const { signOut, user } = useAuthContext()
  const [formId, setFormId] = useState<string | null>(null)
  const clientName = user?.clientName
  const userName = user?.userName
  const clientId = user?.clientId
  const ambient = user?.ambientLogged

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fromListForms = queryParams.get('formId');
    setFormId(fromListForms);
  }, [clientId, navigate]);

  return (
    <header className={style.headerContainer}>
      <div className={style.headerContent}>
        <div className={style.divName}>
          {!home && (
            <div className={style.back} onClick={() => {
              if (formId) {
                navigate(`/listForms?clientId=${clientId}`)
              } else {
                navigate(`/home?clientId=${clientId}`)
              }
            }}>
              <Link to={`/listForms?clientId=${clientId}`}>
                <FiArrowLeft color='#FFF' size={24} />
              </Link>
            </div>
          )}
          <Link to={`/home?clientId=${clientId}`}>
            <img
              src={logoImg}
              width={190}
              height={60}
              alt='Logo Tech'
            />
          </Link>
          <div className={style.info}>
            <h5>Cliente: {clientName}</h5>
            <h5>Usuário: {userName}</h5>
          </div>
          <div className={style.info}>
            <h5>Ambiente: {ambient}</h5>
          </div>
        </div>

        <div className={style.headerContentButtons}>
          <nav className={style.menuNav}>
            <button onClick={signOut}>
              <FiLogOut color='#FFF' size={24} />
            </button>
          </nav>
        </div>
      </div>
    </header>
  )
}
