import { FieldProps, GroupProps } from "./functionsInterfaces";
import {
  setupApiCreateTemplate,
  setupApiSaveAndPublishForm,
} from "../../services/setupApis";
import {
  getFormSideProps,
  getSkillId,
  getTabId,
} from "./functionsLoadDataForm";
import { displayError, displaySuccess, displayWarning } from "./messageToast";

export const formatNameAlias = async (
  nameForm: string,
  setAliasFormResult: React.Dispatch<React.SetStateAction<string>>
) => {
  let nameFormFormatted = nameForm
    .replace(/[ÁÀÃÂÄ]/g, "A")
    .replace(/[ÉÈÊË]/g, "E")
    .replace(/[ÍÌÎÏ]/g, "I")
    .replace(/[ÓÒÕÔÖ]/g, "O")
    .replace(/[ÚÙÛÜ]/g, "U")
    .replace(/[Ç]/g, "C")
    .replace(/[Ñ]/g, "N")
    .replace(/[áàãâä]/g, "a")
    .replace(/[éèêë]/g, "e")
    .replace(/[íìîï]/g, "i")
    .replace(/[óòõôö]/g, "o")
    .replace(/[úùûü]/g, "u")
    .replace(/[ç]/g, "c")
    .replace(/[ñ]/g, "n")
    .toLowerCase();
  nameFormFormatted = nameFormFormatted
    .replace(/[^\w\s]/gi, "")
    .replace(/\s+/g, "_");
  setAliasFormResult(nameFormFormatted);
};

export const saveForm = async (
  setLoadingButtonSave: React.Dispatch<React.SetStateAction<boolean>>,
  form: any,
  authorization: string,
  authorizationRA: string,
  setButtonPublish: React.Dispatch<React.SetStateAction<boolean>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>,
  setIdFormSaved: React.Dispatch<React.SetStateAction<string>>,
  nameForm: string,
  skillIdForm: string,
  tabIdForm: string,
  aliasFormResult: string,
  ambient: string,
  setTabName: React.Dispatch<React.SetStateAction<string>>,
  setSkillName: React.Dispatch<React.SetStateAction<string>>
) => {
  setLoadingButtonSave(true);

  if (nameForm === "") {
    displayWarning("Preencha o nome do formulário!");
    return;
  }

  if (skillIdForm === "") {
    displayWarning("Preencha a habilidade!");
    return;
  }

  if (tabIdForm === "") {
    displayWarning("Preencha a tabulação!");
    return;
  }

  if (aliasFormResult === "") {
    displayWarning("Preencha o alias do formulário!");
    return;
  }

  await getSkillId(skillIdForm, authorization, ambient, setSkillName);

  await getTabId(tabIdForm, authorization, ambient, setTabName);

  function removeEmptyFields(elements: any): void {
    for (let i = elements.length - 1; i >= 0; i--) {
      const element = elements[i];
      if (element.id === "" || element.name === "") {
        elements.splice(i, 1);
        // displayWarning("Todos os campos vazios removidos!");
      } else if (element.children) {
        removeEmptyFields(element.children);
      }
    }
  }

  function cleanForm(form: any): void {
    removeEmptyFields(form.template.children);
  }

  cleanForm(form);

  try {
    const apiClient = setupApiSaveAndPublishForm(undefined, authorizationRA);
    const response = await apiClient.put(
      `/update-form?ambient=${ambient}`,
      form,
      {
        maxBodyLength: Infinity,
      }
    );

    setIdFormSaved(response.data.form.id);
    setButtonPublish(true);
    setButtonSave(false);
    setLoadingButtonSave(false);
    displaySuccess("Formulário salvo!");
  } catch (error: any) {
    displayError("Erro ao salvar o formulário!");
    return;
  }
};

export const publishForm = async (
  setExpandedPages: React.Dispatch<React.SetStateAction<string[]>>,
  setShowTopPageContainer: React.Dispatch<React.SetStateAction<boolean>>,
  setNamePage: React.Dispatch<React.SetStateAction<string>>,
  setPageId: React.Dispatch<React.SetStateAction<string>>,
  setIsData: React.Dispatch<React.SetStateAction<boolean>>,
  setLoadingButtonPublish: React.Dispatch<React.SetStateAction<boolean>>,
  idFormSaved: string,
  setSearch: React.Dispatch<React.SetStateAction<string>>,
  setFormId: React.Dispatch<React.SetStateAction<string>>,
  setNameForm: React.Dispatch<React.SetStateAction<string>>,
  setSkillIdForm: React.Dispatch<React.SetStateAction<string>>,
  setTabIdForm: React.Dispatch<React.SetStateAction<string>>,
  setItemId: React.Dispatch<React.SetStateAction<string>>,
  setGroups: React.Dispatch<React.SetStateAction<GroupProps[]>>,
  setFields: React.Dispatch<React.SetStateAction<FieldProps[]>>,
  setShowTopContainer: React.Dispatch<React.SetStateAction<boolean>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>,
  setButtonPublish: React.Dispatch<React.SetStateAction<boolean>>,
  clientId: string,
  apiGetFormsAndLogin: any,
  authorizationRA: string,
  aliasFormResult: string,
  setAliasFormResult: React.Dispatch<React.SetStateAction<string>>,
  setForm: React.Dispatch<React.SetStateAction<any>>,
  checkTheFunction: boolean,
  ambient: string,
  setTabName: React.Dispatch<React.SetStateAction<string>>,
  setSkillName: React.Dispatch<React.SetStateAction<string>>,
  authorization: string
) => {
  try {
    setLoadingButtonPublish(true);
    if (idFormSaved === "") {
      displayWarning("Salve o formulário antes de publicar!");
      return;
    }
    const apiClient = setupApiSaveAndPublishForm(undefined, authorizationRA);
    await apiClient.post(
      `/publish-form?formId=${idFormSaved}&ambient=${ambient}`
    );
    displaySuccess("Formulário publicado!");
    setButtonSave(true);
    setButtonPublish(false);
    setSearch(aliasFormResult);
    setLoadingButtonPublish(false);

    if (checkTheFunction) {
      await getFormSideProps(
        setExpandedPages,
        setShowTopPageContainer,
        setNamePage,
        setPageId,
        setIsData,
        aliasFormResult,
        setFormId,
        setNameForm,
        setSkillIdForm,
        setTabIdForm,
        setItemId,
        setGroups,
        setFields,
        setShowTopContainer,
        setButtonSave,
        setButtonPublish,
        clientId,
        apiGetFormsAndLogin,
        setAliasFormResult,
        setForm,
        ambient,
        setSkillName,
        authorization,
        setTabName
      );
    }
  } catch (error: any) {
    displayError("Erro ao publicar o formulário!");
    return;
  }
};

export const createForm = async (
  setLoadingButtonCreate: React.Dispatch<React.SetStateAction<boolean>>,
  payload: any,
  setForms: React.Dispatch<React.SetStateAction<string>>,
  authorization: string,
  authorizationRA: string,
  nameForm: string,
  skillIdForm: string,
  tabIdForm: string,
  idForm: string,
  ambient: string,
  setTabName: React.Dispatch<React.SetStateAction<string>>,
  setSkillName: React.Dispatch<React.SetStateAction<string>>
) => {
  setLoadingButtonCreate(true);

  if (payload === "") {
    displayWarning("Preencha a area com o payload");
    setLoadingButtonCreate(false);
    return;
  }

  if (nameForm === "") {
    displayWarning("Preencha o nome do formulário!");
    setLoadingButtonCreate(false);
    return;
  }

  if (skillIdForm === "") {
    displayWarning("Preencha a habilidade!");
    setLoadingButtonCreate(false);
    return;
  }

  if (tabIdForm === "") {
    displayWarning("Preencha a tabulação!");
    setLoadingButtonCreate(false);
    return;
  }

  await getSkillId(skillIdForm, authorization, ambient, setSkillName);
  await getTabId(tabIdForm, authorization, ambient, setTabName);

  try {
    const apiClientCreateTemplate = setupApiCreateTemplate(
      undefined,
      undefined
    );
    const {
      data: { data },
    } = await apiClientCreateTemplate.post(
      `/create-form-by-payload?name=${nameForm}&skillId=${skillIdForm}&tabId=${tabIdForm}&id=${
        idForm ? idForm : ""
      }`,
      JSON.parse(payload),
      {
        maxBodyLength: Infinity,
      }
    );

    // function removeEmptyFields(elements: any): void {
    //   for (let i = elements.length - 1; i >= 0; i--) {
    //     const element = elements[i];
    //     if (element.id === "" || element.name === "") {
    //       elements.splice(i, 1);
    //       // displayWarning("Todos os campos vazios removidos!");
    //     } else if (element.children) {
    //       removeEmptyFields(element.children);
    //     }
    //   }
    // }

    // function cleanForm(form: any): void {
    //   removeEmptyFields(form.template.children);
    // }

    // cleanForm(form);

    const apiClient = setupApiSaveAndPublishForm(undefined, authorizationRA);
    const {
      data: {
        form: { id },
      },
    } = await apiClient.put(`/update-form?ambient=${ambient}`, data, {
      maxBodyLength: Infinity,
    });

    await apiClient.post(`/publish-form?formId=${id}&ambient=${ambient}`);

    setForms("");
    setLoadingButtonCreate(false);
    displaySuccess("Formulário criado!");
  } catch (error: any) {
    displayError("Erro ao salvar o formulário!");
    return;
  }
};
