import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import style from './styles.module.scss'

import {
  faAngleDown,
  faAngleUp,
  faFileArrowUp,
  faFilePowerpoint,
  faLayerGroup
} from '@fortawesome/free-solid-svg-icons'

import {
  FieldProps,
  GroupProps,
} from '../../utils/functions/functionsInterfaces'

import { SelectFieldPersonalDetails } from '../../components/ui/selects'
import { Draggable, Droppable } from '@hello-pangea/dnd'
import { InputsInfosFields, InputsInfosGroups } from '../ui/inputs'
import { ButtonAddField, ButtonAddFieldAttachments, ButtonCopyGroup, ButtonRemoveGroup } from '../ui/buttons'
import { Field } from '../fields'

interface FormFieldsProps {
  form: any[];
  setForm: React.Dispatch<React.SetStateAction<any[]>>;
  groups: GroupProps[];
  expandedGroups: any[];
  setExpandedGroups: React.Dispatch<React.SetStateAction<any[]>>;
  handleCopyGroup: (groups: GroupProps[],
    groupId: string,
    groupName: string,
    form: any,
    setForm: React.Dispatch<React.SetStateAction<any[]>>,
    uuid: string,
    setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  handleRemoveGroup: (groupId: string,
    form: any,
    setForm: React.Dispatch<React.SetStateAction<any[]>>,
    uuid: string,
    setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  handleAddField: (groupId: string,
    expandedGroups: string[],
    form: any,
    setForm: React.Dispatch<React.SetStateAction<any[]>>,
    setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  handleAddFieldAttachments: (groupId: string,
    expandedGroups: string[],
    form: any,
    setForm: React.Dispatch<React.SetStateAction<any[]>>,
    setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  handleAddPersonalDetailField: (groupId: string,
    groups: GroupProps[],
    expandedGroups: string[],
    form: any,
    setForm: React.Dispatch<React.SetStateAction<any[]>>,
    fieldAdd: string,
    setAddFieldPersonalDetails: React.Dispatch<React.SetStateAction<string>>,
    setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  handleRemoveField: (
    groupId: string | undefined,
    fieldId: string,
    form: any,
    setForm: React.Dispatch<React.SetStateAction<any[]>>,
    uuid: string,
    setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  handleRemoveFieldInGroup: (
    groupId: string | undefined,
    fieldId: string,
    form: any,
    setForm: React.Dispatch<React.SetStateAction<any[]>>,
    uuid: string,
    setButtonSave: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  toggleGroupVisibility: (groupId: string,
    expandedGroups: string[],
    setExpandedGroups: React.Dispatch<React.SetStateAction<string[]>>) => void;
  setAddFieldPersonalDetails: React.Dispatch<React.SetStateAction<string>>;
  addFieldPersonalDetails: string;
  pageId: string;
  setPageId: React.Dispatch<React.SetStateAction<string>>;
  namePage: string;
  setNamePage: React.Dispatch<React.SetStateAction<string>>;
  isCreated: boolean;
  setIsCreated: React.Dispatch<React.SetStateAction<boolean>>;
  expandedPage: string[];
  setExpandedPage: React.Dispatch<React.SetStateAction<string[]>>;
  showTopPageContainer: boolean;
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>;
  authorization: string;
  clientId: string;
  ambient: string;
  formId: string;
}

export default function FormFields({
  form,
  setForm,
  groups,
  expandedGroups,
  setExpandedGroups,
  handleCopyGroup,
  handleRemoveGroup,
  handleAddField,
  handleAddFieldAttachments,
  handleAddPersonalDetailField,
  handleRemoveField,
  handleRemoveFieldInGroup,
  toggleGroupVisibility,
  setAddFieldPersonalDetails,
  addFieldPersonalDetails,
  pageId,
  setPageId,
  namePage,
  setNamePage,
  expandedPage,
  setExpandedPage,
  showTopPageContainer,
  setButtonSave,
  authorization,
  clientId,
  ambient,
  formId,
}: FormFieldsProps) {

  return (
    <>
      {showTopPageContainer && (
        <div className={style.groupContainer}>
          <div className={style.headerGroup}>

            <div className={style.iconIdentifier}>
              <FontAwesomeIcon
                icon={faLayerGroup}
                style={{ color: '#FFD43B' }}
              />
            </div>

            <InputsInfosFields
              placeholder='Digite o ID da pagina'
              value={pageId}
              onChange={(e) => {
                setPageId(e.target.value)
              }}
              onBlur={() => {
                setButtonSave(true)
              }}
            />

            <InputsInfosFields
              placeholder='Digite o nome da pagina'
              value={namePage}
              onChange={(e) => {
                setNamePage(e.target.value)
              }}
              onBlur={() => {
                setButtonSave(true)
              }}
            />

            <button
              type='button'
              onClick={() =>
                toggleGroupVisibility(
                  pageId,
                  expandedPage,
                  setExpandedPage,
                )
              }
              className={`${style.buttonToggleGroupVisibility} ${style.buttonIcon}`}
              title='Esconder Campos'
            >
              {expandedPage.includes(pageId) ? (
                <FontAwesomeIcon
                  icon={faAngleUp}
                  className={style.faIcon}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={style.faIcon}
                />
              )}
            </button>

          </div>
          <div>
            {expandedPage.includes(pageId) &&
              <Droppable droppableId='form-droppable' type='group'>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {form.map((item: any, index: number) => (
                      <Draggable
                        key={index}
                        draggableId={`item-${index}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {item.type === 'group' ? (
                              <div key={index} className={style.groupContainer}>
                                <div className={style.headerGroup}>

                                  <div className={style.iconIdentifier}>
                                    <FontAwesomeIcon
                                      icon={faLayerGroup}
                                      style={{ color: '#FFD43B' }}
                                    />
                                  </div>

                                  <InputsInfosGroups
                                    placeholder='Digite o ID do grupo'
                                    value={item.id}
                                    onChange={(e) => {
                                      const newId = e.target.value
                                      const updatedForm = form.map((group, idx) => {
                                        if (index === idx) {
                                          return { ...group, id: newId }
                                        }
                                        return group
                                      })
                                      setForm(updatedForm)
                                    }}
                                    onBlur={() => {
                                      setButtonSave(true)
                                    }}
                                  />

                                  <InputsInfosGroups
                                    placeholder='Digite o nome do grupo'
                                    value={item.name}
                                    onChange={(e) => {
                                      const newName = e.target.value
                                      const updatedForm = form.map((group, idx) => {
                                        if (index === idx) {
                                          return { ...group, name: newName }
                                        }
                                        return group
                                      })
                                      setForm(updatedForm)
                                    }}
                                    onBlur={() => {
                                      setButtonSave(true)
                                    }}
                                  />

                                  <ButtonAddField
                                    onClick={() =>
                                      handleAddField(
                                        item.uuid,
                                        expandedGroups,
                                        form,
                                        setForm,
                                        setButtonSave
                                      )
                                    }
                                  />

                                  <ButtonAddFieldAttachments
                                    onClick={() =>
                                      handleAddFieldAttachments(
                                        item.id,
                                        expandedGroups,
                                        form,
                                        setForm,
                                        setButtonSave
                                      )
                                    }
                                  />

                                  <ButtonCopyGroup
                                    onClick={() =>
                                      handleCopyGroup(
                                        groups,
                                        item.id,
                                        item.name,
                                        form,
                                        setForm,
                                        item.uuid || '',
                                        setButtonSave
                                      )
                                    }
                                  />

                                  <ButtonRemoveGroup
                                    onClick={() =>
                                      handleRemoveGroup(
                                        item.id,
                                        form,
                                        setForm,
                                        item.uuid,
                                        setButtonSave
                                      )
                                    }
                                  />

                                  <SelectFieldPersonalDetails
                                    value={addFieldPersonalDetails}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLSelectElement>
                                    ) => {
                                      const newValue = event.target.value
                                      setAddFieldPersonalDetails(newValue)
                                      handleAddPersonalDetailField(
                                        item.id,
                                        groups,
                                        expandedGroups,
                                        form,
                                        setForm,
                                        newValue,
                                        setAddFieldPersonalDetails,
                                        setButtonSave
                                      )
                                    }}
                                    title='Adicionar Campos Customizados'
                                  />

                                  <button
                                    type='button'
                                    onClick={() =>
                                      toggleGroupVisibility(
                                        item.id,
                                        expandedGroups,
                                        setExpandedGroups
                                      )
                                    }
                                    className={`${style.buttonToggleGroupVisibility} ${style.buttonIcon}`}
                                    title='Esconder Campos'
                                  >
                                    {expandedGroups.includes(item.id) ? (
                                      <FontAwesomeIcon
                                        icon={faAngleUp}
                                        className={style.faIcon}
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faAngleDown}
                                        className={style.faIcon}
                                      />
                                    )}
                                  </button>

                                </div>
                                <div>
                                  {expandedGroups.includes(item.id) &&
                                    item.children.map(
                                      (field: any, indexField: number) => {
                                        /* campo de anexo dentro do grupo */
                                        if (field.type === 'group') {
                                          return (
                                            <div key={field.uuid} className={style.groupContainer}>
                                              <div className={style.headerGroup}>
                                                <div className={style.iconIdentifier}>
                                                  <FontAwesomeIcon
                                                    icon={faLayerGroup}
                                                    style={{ color: '#FFD43B' }}
                                                  />
                                                </div>

                                                <InputsInfosGroups
                                                  placeholder='Digite o ID do grupo'
                                                  value={item.id}
                                                  onChange={(e) => {
                                                    const newId = e.target.value
                                                    const updatedForm = form.map((group, idx) => {
                                                      if (index === idx) {
                                                        return { ...group, id: newId }
                                                      }
                                                      return group
                                                    })
                                                    setForm(updatedForm)
                                                  }}
                                                />

                                                <InputsInfosGroups
                                                  placeholder='Digite o nome do grupo'
                                                  value={item.name}
                                                  onChange={(e) => {
                                                    const newName = e.target.value
                                                    const updatedForm = form.map((group, idx) => {
                                                      if (index === idx) {
                                                        return { ...group, name: newName }
                                                      }
                                                      return group
                                                    })
                                                    setForm(updatedForm)
                                                  }}
                                                />

                                                <ButtonAddField
                                                  onClick={() =>
                                                    handleAddField(
                                                      item.uuid,
                                                      expandedGroups,
                                                      form,
                                                      setForm,
                                                      setButtonSave
                                                    )
                                                  }
                                                />

                                                <ButtonAddFieldAttachments
                                                  onClick={() =>
                                                    handleAddFieldAttachments(
                                                      item.id,
                                                      expandedGroups,
                                                      form,
                                                      setForm,
                                                      setButtonSave
                                                    )
                                                  }
                                                />

                                                <ButtonCopyGroup
                                                  onClick={() =>
                                                    handleCopyGroup(
                                                      groups,
                                                      item.id,
                                                      item.name,
                                                      form,
                                                      setForm,
                                                      item.uuid || '',
                                                      setButtonSave
                                                    )
                                                  }
                                                />

                                                <ButtonRemoveGroup
                                                  onClick={() =>
                                                    handleRemoveGroup(
                                                      item.id,
                                                      form,
                                                      setForm,
                                                      item.uuid,
                                                      setButtonSave
                                                    )
                                                  }
                                                />

                                                <SelectFieldPersonalDetails
                                                  value={addFieldPersonalDetails}
                                                  onChange={(
                                                    event: React.ChangeEvent<HTMLSelectElement>
                                                  ) => {
                                                    const newValue = event.target.value
                                                    setAddFieldPersonalDetails(newValue)
                                                    handleAddPersonalDetailField(
                                                      item.id,
                                                      groups,
                                                      expandedGroups,
                                                      form,
                                                      setForm,
                                                      newValue,
                                                      setAddFieldPersonalDetails,
                                                      setButtonSave
                                                    )
                                                  }}
                                                  title='Adicionar Campos Customizados'
                                                />

                                                <button
                                                  type='button'
                                                  onClick={() =>
                                                    toggleGroupVisibility(
                                                      item.id,
                                                      expandedGroups,
                                                      setExpandedGroups
                                                    )
                                                  }
                                                  className={`${style.buttonToggleGroupVisibility} ${style.buttonIcon}`}
                                                  title='Esconder Campos'
                                                >
                                                  {expandedGroups.includes(item.id) ? (
                                                    <FontAwesomeIcon
                                                      icon={faAngleUp}
                                                      className={style.faIcon}
                                                    />
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      icon={faAngleDown}
                                                      className={style.faIcon}
                                                    />
                                                  )}
                                                </button>

                                              </div>
                                              <div>
                                                {expandedGroups.includes(field.id) &&
                                                  field.children.map((fieldGroupInGroup: FieldProps, indexFieldGroupInGroup: number) => {
                                                    if (fieldGroupInGroup.fieldType === 'personal_details') {
                                                      return (
                                                        <div key={`${fieldGroupInGroup.uuid}-${indexFieldGroupInGroup}`} className={style.fieldContainer}>

                                                          <div className={style.iconIdentifier}>
                                                            <FontAwesomeIcon icon={faFilePowerpoint} style={{ color: '#63E6BE' }} />
                                                          </div>

                                                          <Field
                                                            fieldGroupInGroup={fieldGroupInGroup}
                                                            indexFieldGroupInGroup={indexFieldGroupInGroup}
                                                            index={index}
                                                            field={field}
                                                            handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                                                            handleRemoveField={handleRemoveField}
                                                            form={form}
                                                            setForm={setForm}
                                                            outsideTheGroup={false}
                                                            isPersonalDetail={true}
                                                            setButtonSave={setButtonSave}
                                                            authorization={authorization}
                                                            clientId={clientId}
                                                            ambient={ambient}
                                                            formId={formId}
                                                            groupId={item.id}
                                                          />

                                                        </div>
                                                      );
                                                    } else if (fieldGroupInGroup.fieldType === 'multiple_files') {
                                                      return (
                                                        <div key={`${fieldGroupInGroup.uuid}-${indexFieldGroupInGroup}`} className={style.fieldContainer}>
                                                          <div className={style.iconIdentifier}>
                                                            <FontAwesomeIcon icon={faFileArrowUp} style={{ color: '#0000fa' }} />
                                                          </div>

                                                          <Field
                                                            fieldGroupInGroup={fieldGroupInGroup}
                                                            indexFieldGroupInGroup={indexFieldGroupInGroup}
                                                            index={index}
                                                            field={field}
                                                            handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                                                            handleRemoveField={handleRemoveField}
                                                            form={form}
                                                            setForm={setForm}
                                                            outsideTheGroup={false}
                                                            isPersonalDetail={false}
                                                            setButtonSave={setButtonSave}
                                                            authorization={authorization}
                                                            clientId={clientId}
                                                            ambient={ambient}
                                                            formId={formId}
                                                            groupId={item.id}
                                                          />

                                                        </div>
                                                      );
                                                    } else {
                                                      return (
                                                        <div key={`${fieldGroupInGroup.uuid}-${indexFieldGroupInGroup}`} className={style.fieldContainer}>

                                                          <Field
                                                            fieldGroupInGroup={fieldGroupInGroup}
                                                            indexFieldGroupInGroup={indexFieldGroupInGroup}
                                                            index={index}
                                                            field={field}
                                                            handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                                                            handleRemoveField={handleRemoveField}
                                                            form={form}
                                                            setForm={setForm}
                                                            outsideTheGroup={false}
                                                            isPersonalDetail={false}
                                                            setButtonSave={setButtonSave}
                                                            authorization={authorization}
                                                            clientId={clientId}
                                                            ambient={ambient}
                                                            formId={formId}
                                                            groupId={item.id}
                                                          />

                                                        </div>
                                                      );
                                                    }
                                                  })}
                                              </div>
                                            </div>
                                          )
                                        } else
                                          if (field.fieldType === 'multiple_files') {
                                            return (
                                              <div
                                                key={field.uuid}
                                                className={style.fieldContainer}
                                              >
                                                <div className={style.iconIdentifier}>
                                                  <FontAwesomeIcon
                                                    icon={faFileArrowUp}
                                                    style={{ color: '#0000fa' }}
                                                  />
                                                </div>

                                                <Field
                                                  fieldGroupInGroup={field}
                                                  indexFieldGroupInGroup={indexField}
                                                  index={index}
                                                  field={field}
                                                  handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                                                  handleRemoveField={handleRemoveField}
                                                  form={form}
                                                  setForm={setForm}
                                                  outsideTheGroup={false}
                                                  isPersonalDetail={false}
                                                  setButtonSave={setButtonSave}
                                                  authorization={authorization}
                                                  clientId={clientId}
                                                  ambient={ambient}
                                                  formId={formId}
                                                  groupId={item.id}
                                                />

                                              </div>
                                            )
                                          } else if (
                                            field.fieldType === 'personal_details'
                                          ) {
                                            // campo personal details dentro do grupo
                                            return (
                                              <div
                                                key={field.uuid}
                                                className={style.fieldContainer}
                                              >
                                                <div className={style.iconIdentifier}>
                                                  <FontAwesomeIcon
                                                    icon={faFilePowerpoint}
                                                    style={{ color: '#63E6BE' }}
                                                  />
                                                </div>

                                                <Field
                                                  fieldGroupInGroup={field}
                                                  indexFieldGroupInGroup={indexField}
                                                  index={index}
                                                  field={field}
                                                  handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                                                  handleRemoveField={handleRemoveField}
                                                  form={form}
                                                  setForm={setForm}
                                                  outsideTheGroup={false}
                                                  isPersonalDetail={true}
                                                  setButtonSave={setButtonSave}
                                                  authorization={authorization}
                                                  clientId={clientId}
                                                  ambient={ambient}
                                                  formId={formId}
                                                  groupId={item.id}
                                                />

                                              </div>
                                            )
                                          } else {
                                            /* campo padrao dentro do grupo */
                                            return (
                                              <div
                                                key={field.uuid}
                                                className={style.fieldContainer}
                                              >

                                                <Field
                                                  fieldGroupInGroup={field}
                                                  indexFieldGroupInGroup={indexField}
                                                  index={index}
                                                  field={field}
                                                  handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                                                  handleRemoveField={handleRemoveField}
                                                  form={form}
                                                  setForm={setForm}
                                                  outsideTheGroup={false}
                                                  isPersonalDetail={false}
                                                  setButtonSave={setButtonSave}
                                                  authorization={authorization}
                                                  clientId={clientId}
                                                  ambient={ambient}
                                                  formId={formId}
                                                  groupId={item.id}
                                                />

                                              </div>
                                            )
                                          }
                                      }
                                    )}
                                </div>
                              </div>
                            ) : item.fieldType === 'multiple_files' ? (
                              /* campo de anexo fora do grupo */
                              <div key={item.uuid} className={style.fieldContainer}>
                                <div className={style.iconIdentifier}>
                                  <FontAwesomeIcon
                                    icon={faFileArrowUp}
                                    style={{ color: '#0000fa' }}
                                  />
                                </div>

                                <Field
                                  fieldGroupInGroup={item}
                                  indexFieldGroupInGroup={index}
                                  index={index}
                                  field={item}
                                  handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                                  handleRemoveField={handleRemoveField}
                                  form={form}
                                  setForm={setForm}
                                  outsideTheGroup={true}
                                  isPersonalDetail={false}
                                  setButtonSave={setButtonSave}
                                  authorization={authorization}
                                  clientId={clientId}
                                  ambient={ambient}
                                  formId={formId}
                                  groupId={undefined}
                                />

                              </div>
                            ) : item.fieldType === 'personal_details' ? (
                              /* campo personal details fora do grupo */
                              <div key={index} className={style.fieldContainer}>
                                <div className={style.iconIdentifier}>
                                  <FontAwesomeIcon
                                    icon={faFilePowerpoint}
                                    style={{ color: '#63E6BE' }}
                                  />
                                </div>

                                <Field
                                  fieldGroupInGroup={item}
                                  indexFieldGroupInGroup={index}
                                  index={index}
                                  field={item}
                                  handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                                  handleRemoveField={handleRemoveField}
                                  form={form}
                                  setForm={setForm}
                                  outsideTheGroup={true}
                                  isPersonalDetail={true}
                                  setButtonSave={setButtonSave}
                                  authorization={authorization}
                                  clientId={clientId}
                                  ambient={ambient}
                                  formId={formId}
                                  groupId={undefined}
                                />

                              </div>
                            ) : (
                              /* campo padrao fora do grupo */
                              <div key={index} className={style.fieldContainer}>

                                <Field
                                  fieldGroupInGroup={item}
                                  indexFieldGroupInGroup={index}
                                  index={index}
                                  field={item}
                                  handleRemoveFieldInGroup={handleRemoveFieldInGroup}
                                  handleRemoveField={handleRemoveField}
                                  form={form}
                                  setForm={setForm}
                                  outsideTheGroup={true}
                                  isPersonalDetail={false}
                                  setButtonSave={setButtonSave}
                                  authorization={authorization}
                                  clientId={clientId}
                                  ambient={ambient}
                                  formId={formId}
                                  groupId={undefined}
                                />

                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            }
          </div>
        </div>
      )}
    </>
  )

}