import { useNavigate } from 'react-router-dom'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { setupApiGetFormsAndLogin } from '../services/setupApis'
import { displayError, displaySuccess } from '../utils/functions/messageToast'

export const AUTHORIZATION_KEY = 'Authorization' as const
export const AUTHORIZATION_RA_KEY = 'AuthorizationRA' as const
export const SESSION_KEY = 'SESSION_KEY' as const

export type AuthContextData = {
  signIn: (credentials: SignInProps) => Promise<void>
  signOut: () => void
  authorizationRA: string
  authorization: string
  user: LoggedInUserProps | null
}

export type SignInProps = {
  instance: string
  email: string
  password: string
  ambient: string
  // reCaptchaToken: string
}

export type LoggedInUserProps = {
  clientName: string
  clientId: string
  instance: string
  userName: string
  ambientLogged: string
  ambient: string
  userEmail: string
  loginTimestamp: number
}

type AuthProviderProps = {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

export function AuthProvider({ children }: AuthProviderProps) {

  const navigate = useNavigate()

  const [authorizationRA, setAuthorizationRA] = useState<string>('')
  const [authorization, setAuthorization] = useState<string>('')
  const [user, setUser] = useState<LoggedInUserProps | null>(null)

  useEffect(() => {
    const authorizationData = JSON.stringify(
      localStorage.getItem(AUTHORIZATION_KEY)
    )
    const authorizationParsed = JSON.parse(authorizationData ?? '')

    const authorizationRAData = JSON.stringify(
      localStorage.getItem(AUTHORIZATION_RA_KEY)
    )
    const authorizationRAParsed = JSON.parse(authorizationRAData ?? '')

    const userData = localStorage.getItem(SESSION_KEY)
    const userParsed = userData ? JSON.parse(userData) : ''

    if (
      !authorizationParsed &&
      !authorizationRAParsed &&
      !userParsed &&
      location.pathname !== '/'
    ) {
      // console.log('Invalid')
      navigate('/')
    } else {
      setAuthorization(authorizationParsed)
      setAuthorizationRA(authorizationRAParsed)
      setUser(userParsed)
    }
  }, [navigate, authorizationRA, authorization])

  async function signIn(credentials: SignInProps) {
    try {

      const apiGetFormsAndLogin = setupApiGetFormsAndLogin(
        undefined,
        AuthContext
      )

      const response = await apiGetFormsAndLogin.post('/login-tech', {
        slug: credentials.instance,
        email: credentials.email,
        password: credentials.password,
        ambient: credentials.ambient,
        // reCaptchaToken: credentials.reCaptchaToken,
      })

      let ambientLogged = ''
      if (credentials.ambient === 'prod') {
        ambientLogged = 'Produção'
      } else if (credentials.ambient === 'hml') {
        ambientLogged = 'Homologação'
      }

      const loggedInUserProps = {
        clientName: response.data.company.name,
        clientId: response.data.company.id,
        userName: response.data.user.name,
        ambient: credentials.ambient,
        ambientLogged,
        userEmail: response.data.user.email,
        instance: credentials.instance,
        loginTimestamp: new Date().getTime(),
      }

      setUser(loggedInUserProps as LoggedInUserProps)

      localStorage.setItem(
        AUTHORIZATION_KEY,
        response.data.tokens.Authorization
      )
      localStorage.setItem(
        AUTHORIZATION_RA_KEY,
        response.data.tokens.AuthorizationRA
      )
      localStorage.setItem(SESSION_KEY, JSON.stringify(loggedInUserProps))

      displaySuccess('Login efetuado com sucesso!')

      navigate(`/home?clientId=${response.data.company.id}`)
    } catch (error) {
      // console.log(error)
      displayError('Erro ao fazer login!')
    }
  }

  function signOut() {
    try {
      localStorage.clear()
      navigate('/')
    } catch (error) {
      displayError('Erro ao deslogar!')
    }
  }

  return (
    <AuthContext.Provider value={{ signIn, signOut, authorizationRA, authorization, user }}>
      {children}
    </AuthContext.Provider>
  )
}
