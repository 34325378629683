import React, { FormEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
// import ReCAPTCHA from 'react-google-recaptcha';
import logo from '../../assets/tech4humans.png';
import { displayWarning } from '../../utils/functions/messageToast';
import { useAuthContext } from '../../hooks/auth';
import { Select } from '../../components/ui/selects';
import { Input } from '../../components/ui/inputs';
import style from './style.module.scss';
import { ButtonLogin } from '../../components/ui/buttons';

const Login: React.FC = () => {
  const { signIn } = useAuthContext();
  const [instance, setInstance] = useState('');
  const [ambient, setAmbient] = useState('');
  const [email, setEmail] = useState('');//automacao@tech4h.com.br
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [cacheCleared, setCacheCleared] = useState(false);
  // const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);

  // const recaptchaRef = React.useRef<any>();

  // const onSubmitWithReCAPTCHA = async () => {
  //   const token = await recaptchaRef.current?.executeAsync();
  //   setReCaptchaToken(token);
  // }

  const useIsomorphicEffect = useEffect;

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        if (!window.location.search.includes('reloaded=true')) {
          window.location.replace(window.location.href + '?reloaded=true');
        }
      }
    } catch (error) {
      return error;
    }
  };

  useIsomorphicEffect(() => {
    if (!cacheCleared) {
      clearCacheClient();
    }
  }, [cacheCleared]);

  async function handleLogin(event: FormEvent) {
    event.preventDefault();

    // if (!reCaptchaToken) {
    //   // displayWarning('Por favor, complete o reCAPTCHA!');
    //   return;
    // }

    if (ambient === '') {
      displayWarning('Selecione o ambiente!');
      return;
    }

    if (instance === '') {
      displayWarning('Preencha o domínio da empresa!');
      return;
    }

    if (email === '') {
      displayWarning('Preencha o email!');
      return;
    }

    if (password === '') {
      displayWarning('Preencha a senha!');
      return;
    }

    setLoading(true);

    const data = {
      instance,
      email,
      password,
      ambient,
      // reCaptchaToken,
    };

    await signIn(data);

    setLoading(false);
  }

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAmbient(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Tech Forms - Login</title>
      </Helmet>

      <div className={style.containerCenter}>
        <div className={style.login}>
          <img src={logo} alt="Logo Tech" />

          <form onSubmit={(event) => {
            // onSubmitWithReCAPTCHA();
            handleLogin(event);
          }}>

            <div className={style.containerAviso}>
              <h4 className={style.aviso}>Faça o login com usuario de automação</h4>
            </div>

            <Select value={ambient} onChange={handleChange}>
              <option value="" disabled>
                Selecione o ambiente
              </option>
              <option value="prod">Produção</option>
              <option value="hml">Homologação</option>
            </Select>

            <Input
              placeholder="Domínio da empresa"
              type="text"
              value={instance}
              onChange={(e) => setInstance(e.target.value)}
              autoComplete="company-domain"
            />

            <Input
              placeholder="E-mail"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />

            <Input
              placeholder="Senha"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />

            {/* <label>
              <input
                type="checkbox"
                checked={rememberPassword}
                onChange={(e) => setRememberPassword(e.target.checked)}
              />
              Lembrar-me
            </label> */}

            {/* <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey="6Le0LCsqAAAAAGOuAj3WcTu13OGOQTPf25voo8sL"
            // onChange={onRecaptchaChange}
            /> */}

            <ButtonLogin type="submit" loading={loading}>
              Login
            </ButtonLogin>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
