import React, { useState } from 'react'
import { Header } from '../../components/Header'
import style from './style.module.scss'
import { Helmet } from 'react-helmet-async';

import {
  createForm,
} from '../../utils/functions/functionsForms'

import {
  getSkillId,
  getTabId,
} from '../../utils/functions/functionsLoadDataForm'

import {
  handleInputNameChange,
  handleInputSkillChange,
  handleInputTabChange,
} from '../../utils/functions/functionsInputs'

import { useAuthContext } from '../../hooks/auth'
import { InputsInfosPages, InputSkill, InputTab } from '../../components/ui/inputs'
import { ButtonCreateForm } from '../../components/ui/buttons'

export default function CreateTemplate() {

  const { authorizationRA, authorization, user } = useAuthContext()
  const ambient = user?.ambient as string
  const [nameForm, setNameForm] = useState('')
  const [skillIdForm, setSkillIdForm] = useState('')//2d323423-4d2f-4065-a0e7-bbaeea0e7cb9
  const [skillName, setSkillName] = useState('')
  const [tabIdForm, setTabIdForm] = useState('')//95645f93-aff4-4166-af3a-ba4594764cbf
  const [tabName, setTabName] = useState('')
  const [idForm, setIdForm] = useState('')
  const [forms, setForms] = useState('')
  const [loadingButtonCreate, setLoadingButtonCreate] = useState(false)

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Criar fomulário por payload</title>
      </Helmet>
      <Header />

      <div className={style.container}>

        <div className={style.inputsHeader}>

          <div className={style.containerRowObject}>
            <div className={style.rowObjects}>
              <p className={style.titles}>Nome</p>
              <InputsInfosPages
                placeholder='Insira o nome do formulário'
                type='text'
                value={nameForm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputNameChange(event, setNameForm)
                }
              />
            </div>
          </div>

          <div className={style.containerRowObject}>
            <div className={style.rowObjects}>
              <p className={style.titles}>SkillId</p>
              <InputSkill
                placeholder='Insira a skillId'
                type='text'
                skillName={skillName}
                value={skillIdForm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputSkillChange(event, setSkillIdForm)
                }
                onBlur={() => getSkillId(skillIdForm, authorization, ambient, setSkillName)}
              />
            </div>
          </div>

          <div className={style.containerRowObject}>
            <div className={style.rowObjects}>
              <p className={style.titles}>TabId</p>
              <InputTab
                placeholder='Insira a tabId'
                type='text'
                tabName={tabName}
                value={tabIdForm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleInputTabChange(event, setTabIdForm)
                }
                onBlur={() => getTabId(tabIdForm, authorization, ambient, setTabName)}
              />
            </div>
          </div>

          <div className={style.containerRowObject}>
            <div className={style.rowObjects}>
              <p className={style.titles}>id</p>
              <InputsInfosPages
                placeholder='Insira o id do formulário'
                type='text'
                value={idForm}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setIdForm(event.target.value)
                }
              />
            </div>
          </div>

        </div>

        <div className={style.containerButton}>
          <ButtonCreateForm
            loadingButtonCreate={loadingButtonCreate}
            onClick={() =>
              createForm(
                setLoadingButtonCreate,
                forms,
                setForms,
                authorization,
                authorizationRA,
                nameForm,
                skillIdForm,
                tabIdForm,
                idForm,
                ambient,
                setTabName,
                setSkillName
              )
            } />

        </div>

        <textarea
          value={forms}
          onChange={(e) => setForms(e.target.value)}
          className={style.jsonTextarea}
          placeholder=""
        />

      </div>
    </div>
  )
}