import { useAuthContext } from '../../hooks/auth'
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Header } from '../../components/Header'
import style from './style.module.scss'
import { useEffect, useState } from 'react';

export default function Home() {
  const { user } = useAuthContext()
  const clientId = user?.clientId as string
  const [cacheCleared, setCacheCleared] = useState(false)

  const useIsomorphicEffect = useEffect

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys()

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)))

        setCacheCleared(true)

        if (!window.location.search.includes("reloaded=true")) {
          window.location.replace(window.location.href + "?reloaded=true")
        }
      }
    } catch (error) {
      return error
    }
  }

  useIsomorphicEffect(() => {
    if (!cacheCleared) {
      clearCacheClient()
    }
  }, [cacheCleared])

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Home</title>
      </Helmet>
      <Header home />

      <div className={style.container}>
        <div className={style.topContainer}>
          <Link to={`/createForm?clientId=${clientId}`}>
            <button className={style.button}>
              Criar novo formulário
            </button>
          </Link>

          <Link to={`/updateForm?clientId=${clientId}`}>
            <button className={style.button}>
              Atualizar formulário
            </button>
          </Link>

          <Link to={`/listForms?clientId=${clientId}`}>
            <button className={style.button}>
              Lista de Formulários
            </button>
          </Link>

          <Link to={`/createTemplate?clientId=${clientId}`}>
            <button className={style.button}>
              Criar form pelo payload
            </button>
          </Link>

          {/* {import.meta.env.MODE !== 'production' && (
            <Link to={`/tests?clientId=${clientId}`}>
              <button className={style.button}>
                Testes
              </button>
            </Link>
          )} */}

        </div>
      </div>
    </div>
  )
}
